import React from "react";
import {
    Alert
} from "react-bootstrap";
import SimpleMDE from "react-simplemde-editor";
import ImageUploadingComponent from "./UploadImageComponent";

class DetailNewCommentField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            content: "",
            images: []
        }
        this.onChange = this.editorChange.bind(this);
        this.onImagesChange = this.imagesChange.bind(this);
        this.imageUploaderRef = React.createRef();
        this.onSubmit = this.submit.bind(this);
        this.simpleMdeOptions = {
            spellChecker: false
        }
    }

    clearContent() {
        this.setState({ content: "", images: [] });
    }

    editorChange(value) {
        this.setState({ content: value });
    }
    
    imagesChange(imageList, addUpdateIndex) {
        console.log(imageList, addUpdateIndex);
        this.setState({ images: imageList });
    }

    submit() {
        this.props.onSubmit(this.state.content, this.state.images, () => {
            this.clearContent();
            if (this.imageUploaderRef.current) {
                this.imageUploaderRef.current.clearImages(); // Clear images via ref
            }
        });
    }
    

    render() {
        return (
            <div className="d-print-none">
                {this.props.error && (
                    <Alert variant="danger">{this.props.error}</Alert>
                )}
                <SimpleMDE
                    value={ this.state.content }
                    onChange={ this.onChange }
                    options={ this.simpleMdeOptions }
                />
                <div className="card">
                    <div className="card-body">
                        <ImageUploadingComponent
                            ref={this.imageUploaderRef}
                            onChanges={this.onImagesChange}
                            allowImages = {this.props.allowImages}
                            allowFiles = {true}
                            handleClose = {this.props.handleClose}
                            images = {this.state.images}
                        />
                    </div>
                </div>
                <div className="mt-3">
                    <button className="btn btn-primary btn-sm" onClick={ this.onSubmit } disabled={ this.props.loading }>
                        { this.props.loading && (
                            <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                        )}
                        Notitie plaatsen
                    </button>
                </div>
            </div>
        )
    }
}

export default React.memo(DetailNewCommentField);
