import React, { useState, useEffect, forwardRef, useImperativeHandle, useCallback } from "react";
import axios from "axios";
import ImageUploading from "react-images-uploading";

const ImageUploadingComponent = forwardRef(({ show, handleClose = null, onChanges, imageUploaded = null, requestParameters, allowImages = true, allowFiles, isDescriptionImage = false }, ref) => {
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [images, setImages] = useState([]);

    useEffect(() => {
        if (show) {
            setError(null);
            setLoading(false);
            setImages([]);
        }
    }, [show]);

    const imagesChange = useCallback((imageList, addUpdateIndex) => {
        if (onChanges) {
            onChanges(imageList, addUpdateIndex);
        }
        console.log(imageList, addUpdateIndex);
        setImages(imageList);
    }, [onChanges]);

    const uploadImage = async (image, additionalRequestParameters = null) => {
        if (image.file === null) {
            return false;
        }

        const formData = new FormData();
        if (requestParameters) {
            Object.entries(requestParameters).forEach(([key, value]) => {
                formData.append(key, value);
            });
        }
        if (additionalRequestParameters) {
            Object.entries(additionalRequestParameters).forEach(([key, value]) => {
                formData.append(key, value);
            });
        }
        if (isDescriptionImage) {
            formData.append("isDescription", true);
        }
        formData.append("file", image.file, image.file.name);
        const fileExtension = image.file.name.split('.').pop().toLowerCase();
        let apiEndpoint = "/uploadFile";
        if(fileExtension === "jpg" || fileExtension === "jpeg" || fileExtension === "png") {
            apiEndpoint = "/uploadImage";
        }

        let response = await axios.post(apiEndpoint, formData, {
            transformRequest: (data, headers) => {
                headers.setContentType(undefined);
                return data;
            }
        });

        return !(!response.data || !response.data.valid);
    };

    const uploadImages = async (additionalRequestParameters) => {
        setLoading(true);
        if(images.length === 0) {
            setLoading(false);
            imageUploaded?.();
            handleClose?.();
            return;
        }

        for (const image of images) {
            if (!await uploadImage(image, additionalRequestParameters)) {
                setLoading(false);
                setError("Er is een fout opgetreden bij het uploaden van de foto's. Niet alle foto's zijn geupload.");
                return;
            }
        }
        setLoading(false);
        imageUploaded?.();
        handleClose?.();

    };

    useImperativeHandle(ref, () => ({
        uploadImages,
        getImages: () => images,
        getError: () => error,
        clearImages: () => setImages([]),
    }));

    const buttonText = allowImages && allowFiles
        ? "Klik hier om een foto of bestand toe te voegen"
        : allowImages
            ? "Klik hier om een foto toe te voegen"
            : "Klik hier om een bestand toe te voegen";

    const allowFileTypes = ["pdf", "docx", "doc", "xlsx", "xls", "csv", "mp4"];
    const allowImageTypes = ["jpg", "png", "jpeg"];

    return (
        <ImageUploading
            multiple
            value={images}
            onChange={imagesChange}
            maxNumber={20}
            dataURLKey="data_url"
            acceptType={allowImages && allowFiles ? [...allowImageTypes, ...allowFileTypes] : allowImages ? allowImageTypes : allowFileTypes}
            allowNonImageType
        >
            {({
                imageList,
                onImageUpload,
                onImageRemoveAll,
                onImageUpdate,
                onImageRemove,
                isDragging,
                dragProps,
            }) => (
                <div className="image-upload">
                    <button
                        className={"btn btn-image-upload" + (isDragging ? " btn-image-upload-dragging" : "")}
                        onClick={(e) => {
                            e.preventDefault();
                            onImageUpload();
                        }}
                        disabled={loading}
                        {...dragProps}
                    >
                        {buttonText}
                    </button>
                    {imageList.length > 0 && (
                        <div className="mt-2">
                            <div className="clearfix">
                                <button className="btn btn-secondary btn-sm float-right" onClick={onImageRemoveAll} disabled={loading}>
                                    Verwijder alle
                                </button>
                            </div>
                            <div className="d-flex flex-wrap mt-2">
                                {imageList.map((file, index) => {
                                    const showImage = (() => {
                                        if (!file?.file) {
                                            return false;
                                        }
                                        switch (file.file.type) {
                                            case "image/jpeg":
                                            case "image/png":
                                                return true;
                                            default:
                                                return false;
                                        }
                                    })()
                                    return (
                                        <>
                                            <div key={index} className="image-item card mr-1 mb-1">
                                                <div className="card-body p-2">
                                                    <button
                                                        className="btn btn-sm btn-remove"
                                                        onClick={() => onImageRemove(index)}
                                                        disabled={loading}
                                                    >
                                                        <i className="fas fa-fw fa-times" />
                                                    </button>
                                                    <button
                                                        className="btn btn-image"
                                                        onClick={() => onImageUpdate(index)}
                                                        disabled={loading}
                                                    >
                                                        {showImage ? (
                                                            <img src={file['data_url']} alt="" width="200" />
                                                        ) : (
                                                            <div style={{ width: "200px" }}>
                                                                {file.file.name}
                                                            </div>
                                                        )}
                                                    </button>
                                                </div>
                                            </div>
                                        </>
                                    );
                                })}
                            </div>
                        </div>
                    )}
                </div>
            )}
        </ImageUploading>
    );
});

export default ImageUploadingComponent;
