import React from "react";
import axios from "axios";
import {
    withRouter
} from "react-router-dom";
import {
    Alert
} from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import {
    Title
} from "@zandor300/backoffice-framework";

import AuthenticatedUserContext from "../../context/AuthenticatedUserContext";
import Helmet from "../../components/Helmet";
import TagPill from "../../components/tagPill";

import TaskCard from "../../components/detail/TaskCard";
import numberFormatter from "../../components/formatters/NumberFormatter";
import EditTaskModal from "../../components/detail/modal/EditTaskModal";
import DeleteTaskModal from "../../components/detail/modal/DeleteTaskModal";
import UploadImageModal from "../../components/detail/modal/UploadImageModal";

class TasksPage extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            tasks: null,
            error: null,
            showUploadImageModal: false,
            uploadImageModalTask: null,
            showEditModal: false,
            editModalTask: null,
            showNewModal: false,
            showDeleteModal: false,
            deleteModalTask: null,
        };
        this.getTasks = this.getTasks.bind(this);
        this.taskUpdated = this.taskUpdated.bind(this);
        this.taskDeleted = this.taskDeleted.bind(this);
        this.onClickUploadImage = this.onClickUploadImage.bind(this);
    }

    componentDidMount() {
        this.getTasks();
    }

    getTasks() {
        this.setState({ tasks: null });
        axios.post("/getUserTasks")
            .then((response) => {
                if(response.data.valid) {
                    this.setState({ tasks: response.data.data });
                } else {
                    this.setState({ tasks: null, error: "Er is een fout opgetreden. Probeer het later opnieuw." });
                }
            })
            .catch((error) => {
                console.error(error);
                this.setState({ tasks: null, error: "Er is een fout opgetreden. Probeer het later opnieuw." });
            });
    }

    openTaskEditModal(task) {
        this.setState({ editModalTask: task, showEditModal: true });
    }

    openTaskDeleteModal(task) {
        this.setState({ deleteModalTask: task, showDeleteModal: true });
    }

    onClickUploadImage(task) {
        this.setState({ uploadImageModalTask: task, showUploadImageModal: true });
    }

    taskUpdated() {
        this.getTasks();
        this.setState({ showEditModal: false });
    }

    taskDeleted() {
        this.getTasks();
        this.setState({ showDeleteModal: false });
    }


    getAmountOfUnsolvedTasks() {
        if(this.state.tasks === null) return 0;
        return this.state.tasks.filter((task) => {
            if(task.completed) {
                return false;
            }
            return true;
        }).length;
    }

    getAmountOfUnsolvedTasksForUser(userId) {
        if(this.state.tasks === null) return 0;
        return this.state.tasks.filter((task) => {
            return !task.completed && (task.assignedUser === null ? 0 : task.assignedUser.id) === userId;
        }).length;
    }

    render() {
        const {
            tasks,
            showUploadImageModal,
            uploadImageModalTask,
            showEditModal,
            editModalTask,
            deleteModalTask,
            showDeleteModal

        } = this.state;

        // Sort tasks: incomplete tasks first sorted by deadline date, then completed tasks sorted by completionDate
        const sortedTasks = tasks ? tasks.slice().sort((a, b) => {
            if (!a.completed && b.completed) return -1;
            if (a.completed && !b.completed) return 1;
            if (!a.completed && !b.completed) {
                if (a.deadlineDate && !b.deadlineDate) return -1;
                if (!a.deadlineDate && b.deadlineDate) return 1;
                if (a.deadlineDate && b.deadlineDate) {
                    return new Date(a.deadlineDate) - new Date(b.deadlineDate);
                }
                return 0;
            }
            if (a.completed && b.completed) {
                return new Date(a.completionDate) - new Date(b.completionDate);
            }
            return 0;
        }) : [];

        return (
            <React.Fragment>
                <Helmet title="Taken"/>

                <UploadImageModal
                    show={ showUploadImageModal }
                    handleClose={ () => this.setState({ showUploadImageModal: false }) }
                    imageUploaded={ () => this.getTasks() }
                    allowFiles={ true }
                    requestParameters={ { taskId: uploadImageModalTask ? uploadImageModalTask.id : null } }
                />
                <EditTaskModal
                    show={ showEditModal}
                    handleClose={ () => this.setState({ showEditModal: false }) }
                    task={ editModalTask}
                    taskUpdated={this.taskUpdated}
                />
                <DeleteTaskModal
                    show={ showDeleteModal}
                    handleClose={ () => this.setState({ showDeleteModal: false }) }
                    task={ deleteModalTask}
                    taskDeleted={this.taskDeleted}
                />

                <Title
                    preTitle="Overzicht"
                >
                    Taken
                    <small className="ml-2">
                        <TagPill>
                            { numberFormatter({ number: this.getAmountOfUnsolvedTasks() }) }
                        </TagPill>
                    </small>
                </Title>

                { this.state.error != null ? (
                    <Alert variant="danger">{ this.state.error }</Alert>
                ) : !tasks ? (
                    <React.Fragment>
                        {[...Array(20)].map((value, index) => (
                            <div className="mb-3" key={ index }>
                                <Skeleton height={ 160 }/>
                            </div>
                        ))}
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        { this.getAmountOfUnsolvedTasks() === 0 && (
                            <div className="text-center mb-4">
                                <React.Fragment>
                                    <h1><i className="fas fa-sparkles"/></h1>
                                    <h3>Geen taken</h3>
                                    <p>
                                        Je bent helemaal bij met alle taken.
                                        <br/>
                                        Nieuwe taken kun je toevoegen bij een lead, installatie, storing of BTW Terugvraag.
                                    </p>
                                </React.Fragment>
                            </div>
                        )}
                        { sortedTasks.map(task => (
                            <TaskCard
                                key={ task.id }
                                task={ task }
                                onTaskUpdated={ this.taskUpdated }
                                onClickUploadImage={ this.onClickUploadImage }
                                onImageRemoved={ () => this.getTasks() }
                                onEdit={ () => this.openTaskEditModal(task) }
                                onDelete={ () => this.openTaskDeleteModal(task) }
                                isTasksPage
                            />
                        ))}
                    </React.Fragment>
                ) }

            </React.Fragment>
        )
    }
}
TasksPage.contextType = AuthenticatedUserContext;

export default withRouter(TasksPage);
