import React from "react";
import {
    Alert,
    Button
} from "react-bootstrap";
import Skeleton from "react-loading-skeleton";

import UploadImageModal from "./modal/UploadImageModal";
import TaskCard from "./TaskCard";
import EditTaskModal from "./modal/EditTaskModal";
import AddTaskModal from "./modal/AddTaskModal";
import DeleteTaskModal from "./modal/DeleteTaskModal";

class DetailTasks extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            tasks: null,
            error: null,
            installationId: this.props.installationId,
            leadId: this.props.leadId,
            outageId: this.props.outageId,
            vatRefundId: this.props.vatRefundId,
            showUploadImageModal: false,
            uploadImageModalTask: null,
            showEditModal: false,
            editModalTask: null,
            showNewModal: false,
            showDeleteModal: false,
            deleteModalTask: null
        }
        this.getTasks = this.getTasks.bind(this);
        this.taskUpdated = this.taskUpdated.bind(this);
        this.taskAdded = this.taskAdded.bind(this);
        this.taskDeleted = this.taskDeleted.bind(this);
        this.onClickUploadImage = this.onClickUploadImage.bind(this);
    }

    componentDidMount() {
        this.getTasks();
    }

    getTasks() {
        throw new Error("DetailTasks.getTasks() not implemented!");
    }

    getEmptyStateDescription() {
        return (
            <p>getEmptyStateDescription() not implemented!</p>
        )
    }

    openTaskEditModal(task) {
        this.setState({ editModalTask: task, showEditModal: true });
    }

    openTaskDeleteModal(task) {
        this.setState({ deleteModalTask: task, showDeleteModal: true });
    }

    onClickUploadImage(task) {
        this.setState({ uploadImageModalTask: task, showUploadImageModal: true });
    }

    taskUpdated() {
        this.getTasks();
        this.setState({ showEditModal: false });
    }

    taskDeleted() {
        this.getTasks();
        this.setState({ showDeleteModal: false });
    }

    taskAdded() {
        this.getTasks();
        this.setState({ showNewModal: false });
    }

    render() {
        const {
            tasks,
            error,
            showUploadImageModal,
            uploadImageModalTask,
            showNewModal,
            showEditModal,
            editModalTask,
            deleteModalTask,
            showDeleteModal

        } = this.state;
        return (
            <React.Fragment>
                <UploadImageModal
                    show={ showUploadImageModal }
                    handleClose={ () => this.setState({ showUploadImageModal: false }) }
                    imageUploaded={ () => this.getTasks() }
                    allowFiles={ true }
                    requestParameters={ { taskId: uploadImageModalTask ? uploadImageModalTask.id : null } }
                />
                <AddTaskModal
                    show={ showNewModal}
                    handleClose={ () => this.setState({ showNewModal: false }) }
                    installationId={ this.state.installationId }
                    leadId={ this.state.leadId }
                    outageId={ this.state.outageId }
                    vatRefundId={ this.state.vatRefundId }
                    taskAdded={this.taskAdded}
                />
                <EditTaskModal
                    show={ showEditModal}
                    handleClose={ () => this.setState({ showEditModal: false }) }
                    task={ editModalTask}
                    taskUpdated={this.taskUpdated}
                />
                <DeleteTaskModal
                    show={ showDeleteModal}
                    handleClose={ () => this.setState({ showDeleteModal: false }) }
                    task={ deleteModalTask}
                    taskDeleted={this.taskDeleted}
                />
                { error ? (
                    <Alert variant="danger">{ error }</Alert>
                ) : !tasks ? (
                    <React.Fragment>
                        {[...Array(20)].map((value, index) => (
                            <div className="mb-3" key={ index }>
                                <Skeleton height={ 122 }/>
                            </div>
                        ))}
                    </React.Fragment>
                ) : tasks.length === 0 ? (
                    <div className="text-center">
                        <h1><i className="fas fa-alarm-clock"/></h1>
                        <h3>Geen taken</h3>
                        { this.getEmptyStateDescription() }
                        <Button variant="primary" size="sm" onClick={ () => this.setState({ showNewModal: true }) }>
                            <i className="fas fa-plus mr-2"/>
                            Nieuwe taak
                        </Button>
                    </div>
                ) : (
                    <React.Fragment>
                        <Button variant="primary" size="sm" className="mb-3" onClick={ () => this.setState({ showNewModal: true }) }>
                            <i className="fas fa-plus mr-2"/>
                            Nieuwe taak
                        </Button>
                        { tasks.map((task) => (
                            <TaskCard
                                key={ task.id }
                                task={ task }
                                onTaskUpdated={ this.taskUpdated }
                                onClickUploadImage={ this.onClickUploadImage }
                                onImageRemoved={ () => this.getTasks() }
                                onEdit={ () => this.openTaskEditModal(task) }
                                onDelete={ () => this.openTaskDeleteModal(task) }
                            />
                        ))}
                    </React.Fragment>
                )}

            </React.Fragment>
        );
    }
}

export default DetailTasks;
