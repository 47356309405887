import React, { useState, useCallback } from "react";
import axios from "axios";
import {
    Alert,
    Button,
    Modal,
    Spinner
} from "react-bootstrap";

import Loading from "../../../components/Loading";

const DeleteTaskModal = ({ show, handleClose, taskDeleted, task }) => {
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    const handleTaskDelete = useCallback(() => {
        setLoading(true);
        axios.post("/removeTask", {
            id: task.id
        })
            .then((response) => {
                if (response.data.valid) {
                    taskDeleted();
                } else {
                    setError("Er ging iets fout. Probeer het later opnieuw.");
                }
            })
            .catch((requestError) => {
                console.error(requestError);
                setError("Er ging iets fout. Probeer het later opnieuw.");
            })
            .finally(() => {
                setLoading(false);
            });
    }, [task, taskDeleted]);

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Task item verwijderen</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {error !== null && (
                    <Alert variant="danger">{error}</Alert>
                )}
                {task !== null ? (
                    <React.Fragment>
                        <p className="text-danger">
                            Weet je zeker dat je de taak wilt verwijderen? Dit kan niet ongedaan worden
                            gemaakt.
                        </p>
                    </React.Fragment>
                ) : (
                    <Loading />
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose} disabled={loading}>
                    Annuleer
                </Button>
                <Button variant="danger" onClick={handleTaskDelete} disabled={loading}>
                    {loading && (
                        <Spinner animation="border" variant="light" size="sm" className="mr-2" />
                    )}
                    Verwijderen
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default DeleteTaskModal;
