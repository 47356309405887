import React, {
    useMemo,
    useState,
    useCallback
} from "react";
import axios from "axios";
import moment from "moment";
import {
    Alert,
    Button,
    OverlayTrigger,
    Tooltip
} from "react-bootstrap";
import {
    Collapse
} from "react-collapse";
import byteSize from "byte-size";
import { useHistory } from "react-router-dom";

import FileIcon from "../FileIcon";
import DeleteFileModal from "./modal/DeleteFileModal";
import CurrentUserHighlight from "../CurrentUserHighlight";
import DateFormatter from "../formatters/DateFormatter";
import ImageGallery from "../../pages/images/ImageGallery";
import NumberFormatter from "../formatters/NumberFormatter";

function TaskCardMetadataTag({ className, tooltipText, children }) {
    const content = useMemo(() => {
        return (
            <div className={"mr-3 mt-3 mt-lg-0" + (className ? " " + className : "")}>
                {children}
            </div>
        )
    }, [className, children]);
    if (!tooltipText) {
        return content;
    }
    return (
        <OverlayTrigger
            overlay={
                <Tooltip id="task-metadata">
                    {tooltipText}
                </Tooltip>
            }
        >
            {content}
        </OverlayTrigger>
    )
}

function FileItem({ file, setFileToDelete, setShowDeleteModal, loading }) {
    const fileSize = byteSize(file.fileSize);
    return (
        <div className="card my-2" key={file.id}>
            <a
                href={file.url}
                target="_blank"
                rel="noopener noreferrer"
                className="card-link text-black-50"
                onClick={(e) => {
                    if (e.defaultPrevented) {
                        return;
                    }
                }}
            >
                <div className="card-body py-2 px-3">
                    <div className="d-flex flex-row align-items-center">
                        <div style={{ fontSize: "1.5rem" }}>
                            <FileIcon path={file.path} className="mr-2" />
                        </div>
                        <div className="flex-grow-1">{file.path.split("/").pop()}</div>
                        <div>{`${fileSize.value} ${fileSize.unit}`}</div>
                        <Button
                            variant=" ml-2"
                            size=""
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                setFileToDelete(file);
                                setShowDeleteModal(true);
                            }}
                            disabled={loading}
                        >
                            <i className="fas fa-trash-alt" />
                        </Button>
                    </div>
                </div>
            </a>
        </div>
    );
}

function TaskCardMetadata({ task }) {
    const taskExpired = useMemo(() => {
        if (task.completed || !task.deadlineDate) {
            return false;
        }
        const deadline = moment(task.deadlineDate);
        const now = moment();
        return deadline < now;
    }, [task]);
    return (
        <div className="d-flex flex-column flex-md-row text-muted" style={{ fontSize: "0.8rem", marginTop: "2px" }}>
            {task.completed && (
                <TaskCardMetadataTag className="text-success" tooltipText="Afgerond door">
                    <i className="fas fa-check mr-2" />
                    <CurrentUserHighlight user={task.completedByUser} />
                </TaskCardMetadataTag>
            )}
            {!task.completed && task.type.type.value === "images" && task.type.requiredAmount > 0 && (
                <TaskCardMetadataTag tooltipText="Aantal foto's vereist voor afronden">
                    <i className="fas fa-images mr-2" />
                    Vereist{" "}
                    <NumberFormatter number={task.type.requiredAmount} />{" "}
                    foto{task.type.requiredAmount === 1 ? "" : "'s"}
                </TaskCardMetadataTag>
            )}
            {!task.completed && task.type.type.value === "files" && task.type.requiredAmount > 0 && (
                <TaskCardMetadataTag tooltipText="Aantal bestanden vereist voor afronden">
                    <i className="fas fa-files mr-2" />
                    Vereist{" "}
                    <NumberFormatter number={task.type.requiredAmount} />{" "}
                    bestand{task.type.requiredAmount === 1 ? "" : "en"}
                </TaskCardMetadataTag>
            )}
            {!task.completed && task.assignedUser && (
                <TaskCardMetadataTag tooltipText="Toegewezen werknemer">
                    <i className="fas fa-user mr-2" />
                    <CurrentUserHighlight user={task.assignedUser} />
                </TaskCardMetadataTag>
            )}
            {task.deadlineDate && !task.completed && (
                <TaskCardMetadataTag className={taskExpired ? " text-danger" : null}>
                    <i className="fas fa-clock mr-2" />
                    <DateFormatter date={task.deadlineDate} message="Deadline" />
                </TaskCardMetadataTag>
            )}
        </div>
    )
}

function TaskCard({ task, onTaskUpdated, onClickUploadImage, onImageRemoved, onEdit, onDelete, isTasksPage }) {
    const [showDetails, setShowDetails] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [fileToDelete, setFileToDelete] = useState(null);

    const history = useHistory();

    const allowImageUpload = useMemo(() => {
        return onClickUploadImage && task.type.type.value === "images";
    }, [onClickUploadImage, task]);

    const allowFileUpload = useMemo(() => {
        return onClickUploadImage && task.type.type.value === "files";
    }, [onClickUploadImage, task]);

    const showUploadImageModal = useMemo(() => {
        return () => {
            if (!onClickUploadImage) {
                return;
            }
            onClickUploadImage(task);
        }
    }, [task, onClickUploadImage]);

    const toggleShowDetails = useMemo(() => {
        return () => {
            setShowDetails(!showDetails);
        }
    }, [showDetails]);

    const toggleCompleted = useMemo(() => {
        return () => {
            if (!task.type.manualCompletion) {
                return;
            }
            const targetCompletionState = !task.completed ? 1 : 0;
            setError(null);
            setLoading(true);
            axios.post("/setTaskCompleted", { taskId: task.id, completed: targetCompletionState })
                .then((response) => {
                    if (response.data.valid) {
                        onTaskUpdated(response.data.task);
                    } else {
                        setError("Er ging iets fout. Probeer het later opnieuw. (" + response.data.error + ")");
                    }
                })
                .catch((requestError) => {
                    console.error(requestError);
                    setError("Er ging iets fout. Probeer het later opnieuw.");
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [task, onTaskUpdated]);

    const getDetailUrl = useCallback((task) => {
        if (task.installationId) {
            return "/installation/" + task.installationId;
        }
        if (task.leadId) {
            return "/lead/" + task.leadId;
        }
        if (task.outageId) {
            return "/outage/" + task.outageId;
        }
        if (task.vatRefundId) {
            return "/vat-refund/" + task.vatRefundId;
        }
        return null;
    }, []);

    const handleDetailButtonClick = useCallback((task) => {
        const url = getDetailUrl(task);
        if (url) {
            history.push(url);
        }
    }, [getDetailUrl, history]);


    return (
        <React.Fragment>
            {error && (
                <Alert variant="danger">{error}</Alert>
            )}
            <DeleteFileModal
                show={showDeleteModal}
                handleClose={() => setShowDeleteModal(false)}
                file={fileToDelete}
                onFileRemoved={onImageRemoved}
            />
            <div className="card mb-2">
                <div className="card-body">
                    <div className="d-flex flex-row">
                        <div className="task-checkbox-container mr-2">
                            <div className="custom-control custom-checkbox">
                                <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id={`taskCheckbox${task.id}`}
                                    disabled={!task.type.manualCompletion || loading}
                                    checked={task.completed}
                                    onChange={toggleCompleted}
                                />
                                <label className="custom-control-label" htmlFor={`taskCheckbox${task.id}`} />
                            </div>
                        </div>
                        <div className="d-flex flex-column flex-grow-1">
                            <div className="flex-grow-1 d-flex flex-column flex-lg-row">
                                <div className="flex-grow-1">
                                    <div>
                                        {task.description}
                                    </div>
                                </div>
                                <TaskCardMetadata task={task} />
                            </div>
                            {(task.descriptionImages?.length > 0 || task.descriptionFiles?.length > 0) && (
                                <div className="mt-2">
                                    <ImageGallery
                                        images={task.descriptionImages}
                                        onImageRemoved={onImageRemoved}
                                        targetRowHeight={150}
                                    />
                                    {task.descriptionFiles?.length > 0 && (
                                        <div className="mt-2">
                                            {task.descriptionFiles.map((file) => {
                                                return (
                                                    <FileItem
                                                        file={file}
                                                        setFileToDelete={setFileToDelete}
                                                        setShowDeleteModal={setShowDeleteModal}
                                                        loading={loading}
                                                    />
                                                );
                                            })}
                                        </div>
                                    )}
                                    <div className="separator" />
                                </div>
                            )}
                            {allowImageUpload && (
                                <div className="mt-3">
                                    <Button variant="link" size="sm" className="px-0" onClick={showUploadImageModal}>
                                        <i className="fas fa-upload mr-2" />
                                        Foto's uploaden
                                    </Button>
                                </div>
                            )}
                            {allowFileUpload && (
                                <div className="mt-3">
                                    <Button variant="link" size="sm" className="px-0" onClick={showUploadImageModal}>
                                        <i className="fas fa-upload mr-2" />
                                        Bestanden uploaden
                                    </Button>
                                </div>
                            )}
                            {task.images && task.images.length > 0 && (
                                <ImageGallery
                                    images={task.images}
                                    onImageRemoved={onImageRemoved}
                                    targetRowHeight={150}
                                />
                            )}
                            {task.files && task.files.length > 0 && (
                                <div className="mb-3">
                                    {task.files.map((file) => {
                                        return (
                                            <FileItem
                                                file={file}
                                                setFileToDelete={setFileToDelete}
                                                setShowDeleteModal={setShowDeleteModal}
                                                loading={loading}
                                            />
                                        );
                                    })}
                                </div>
                            )}
                        </div>
                        <div className="d-flex flex-column justify-content-end">
                            <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip id="showDetails">
                                        Details {showDetails ? "verbergen" : "tonen"}
                                    </Tooltip>
                                }
                            >
                                <Button variant="link" className="py-0 px-1" onClick={toggleShowDetails}>
                                    {showDetails ? (
                                        <i className="far fa-angle-up" />
                                    ) : (
                                        <i className="far fa-angle-down" />
                                    )}
                                </Button>
                            </OverlayTrigger>
                        </div>
                    </div>
                </div>
                <Collapse isOpened={showDetails}>
                    <div className="card-footer d-flex flex-column flex-lg-row justify-content-between align-items-lg-center">
                        <div className=" text-muted">
                            {task.author ? (
                                <>
                                    Aangemaakt door <CurrentUserHighlight user={task.author} />{" "}
                                </>
                            ) : (
                                "Automatisch aangemaakt "
                            )}
                            op <DateFormatter date={task.createdDate} />
                        </div>
                        <div className="d-flex flex-row mt-2 mt-lg-0">
                            {isTasksPage && (
                                <Button variant="primary" size="sm" onClick={() => handleDetailButtonClick(task)}>
                                    <i className="fas fa-link" /> Naar detail
                                </Button>
                            )}
                            {task.type.manualCompletion && task.assignedUser !== null && (
                                <Button variant="danger " size="sm" className="ml-3" onClick={() => onDelete()} disabled={loading}>
                                    <i className="fas fa-trash-alt mr-1" /> Verwijderen
                                </Button>
                            )}
                            <Button variant="primary ml-3" size="sm" onClick={() => onEdit()} disabled={loading}>
                                <i className="fas fa-edit mr-1" /> Bewerken
                            </Button>
                        </div>
                    </div>
                </Collapse>
            </div>
        </React.Fragment>
    )
}

export default React.memo(TaskCard);
