import React from "react";
import axios from "axios";
import moment from "moment";
import {
    withRouter
} from "react-router-dom";
import {
    Alert
} from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import {
    TabBar,
    TabBarItem,
    Title
} from "@zandor300/backoffice-framework";

import AuthenticatedUserContext from "../../context/AuthenticatedUserContext";
import Helmet from "../../components/Helmet";
import TagPill from "../../components/tagPill";

import ReminderCard from "./components/ReminderCard";
import ReminderEditModal from "./modal/ReminderEditModal";
import numberFormatter from "../../components/formatters/NumberFormatter";
import RemindersDatePicker from "./components/RemindersDatePicker";

class RemindersPage extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            reminders: null,
            filteredReminders: null,
            error: null,

            date: null,
            datePickerFocussed: false,

            users: null,
            usersFilter: null,

            types: null,
            typesFilter: null,

            editModalReminder: null,
            showEditModal: false,
            calendarOpen: false
        };
        this.onDateChange = this.dateChange.bind(this);
        this.onFocusChange = this.focusChange.bind(this);
    }

    componentDidMount() {
        const date = this.props.match.params.date ? moment(this.props.match.params.date) : moment();
        this.setState({ date });
        this.getReminders(date);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.showAll !== this.props.showAll || prevProps.match.params.date !== this.props.match.params.date) {
            const date = this.props.match.params.date ? moment(this.props.match.params.date) : moment();
            this.setState({ date });
            this.getReminders(date);
        }
    }

    getReminders(momentDate) {
        const showAll = this.props.showAll;
        const date = (momentDate ? momentDate : moment()).format("YYYY-MM-DD");
        this.setState({ reminders: null, filteredReminders: null, users: null });
        axios.post("/getReminders", { filter: (showAll ?? false) ? "all" : "personal", date })
            .then((response) => {
                if(showAll !== this.props.showAll) {
                    return;
                }
                if(response.data.valid) {
                    this.setState({ reminders: response.data.data }, () => {
                        this.refreshFilters(response.data.data);
                    });
                } else {
                    this.setState({ reminders: null, error: "Er is een fout opgetreden. Probeer het later opnieuw." });
                }
            })
            .catch((error) => {
                console.error(error);
                this.setState({ reminders: null, error: "Er is een fout opgetreden. Probeer het later opnieuw." });
            });
    }

    refreshFilters(reminders) {
        this.setState((prevState) => {
            let users = prevState.users ? [...prevState.users] : [];
            let usersFilter = prevState.usersFilter ? [...prevState.usersFilter] : [0];
            reminders.forEach((reminder) => {
                if(reminder.assignedUser !== null) {
                    if(!users.find((user) => user.id === reminder.assignedUser.id)) {
                        users.push(reminder.assignedUser);
                        usersFilter.push(reminder.assignedUser.id);
                    }
                }
            });
            users.sort((user1, user2) => {
                if(user1.name < user2.name) return -1;
                if(user1.name > user2.name) return 1;
                return 0;
            });

            let types = prevState.types ? [...prevState.types] : [];
            let typesFilter = prevState.typesFilter ? [...prevState.typesFilter] : [];
            reminders.forEach((reminder) => {
                if(!types.find((type) => type.id === reminder.type.id)) {
                    types.push(reminder.type);
                    typesFilter.push(parseInt(reminder.type.id));
                }
            });
            types.sort((type1, type2) => {
                if(type1.id < type2.id) return -1;
                if(type1.id > type2.id) return 1;
                return 0;
            });

            return { users, usersFilter, types, typesFilter };
        }, () => {
            this.filterReminders();
        });
    }

    openReminderEditModal(reminder) {
        this.setState({ editModalReminder: reminder, showEditModal: true });
    }

    reminderUpdated(reminder) {
        this.setState((prevState) => {
            const reminders = prevState.reminders.map((existingReminder) => {
                if(existingReminder.id === reminder.id) {
                    return reminder;
                }
                return existingReminder;
            });
            this.refreshFilters(reminders);
            return { reminders, showEditModal: false };
        }, () => {
            this.filterReminders();
        });
    }

    getAmountOfUnsolvedReminders() {
        if(this.state.reminders === null) return 0;
        return this.state.reminders.filter((reminder) => {
            if(reminder.solved) {
                return false;
            }
            if(this.props.showAll && this.state.usersFilter && this.state.typesFilter) {
                if(!this.state.usersFilter.includes(reminder.assignedUser === null ? 0 : reminder.assignedUser.id)) {
                    return false;
                }
                return this.state.typesFilter && this.state.typesFilter.includes(parseInt(reminder.type.id));
            }
            return true;
        }).length;
    }

    getAmountOfUnsolvedRemindersForUser(userId) {
        if(this.state.reminders === null) return 0;
        return this.state.reminders.filter((reminder) => {
            return !reminder.solved && (reminder.assignedUser === null ? 0 : reminder.assignedUser.id) === userId;
        }).length;
    }

    getAmountOfUnsolvedRemindersWithType(typeId) {
        if(this.state.reminders === null) return 0;
        return this.state.reminders.filter((reminder) => {
            return !reminder.solved && reminder.type.id === typeId;
        }).length;
    }

    updateFilterUser(event, userId) {
        this.setState((prevState) => {
            if (event.target.checked) {
                if (!prevState.usersFilter.includes(userId)) {
                    let filter = [...prevState.usersFilter];
                    filter.push(userId);
                    return { usersFilter: filter };
                }
            } else {
                if (prevState.usersFilter.includes(userId)) {
                    let filter = [...prevState.usersFilter];
                    let index = filter.indexOf(userId);
                    if (index > -1) {
                        filter.splice(index, 1);
                    }
                    return { usersFilter: filter };
                }
            }
        }, () => {
            this.filterReminders();
        });
    }

    updateFilterType(event, typeId) {
        this.setState((prevState) => {
            if (event.target.checked) {
                if (!prevState.typesFilter.includes(parseInt(typeId))) {
                    let filter = [...prevState.typesFilter];
                    filter.push(parseInt(typeId));
                    return { typesFilter: filter };
                }
            } else {
                if (prevState.typesFilter.includes(parseInt(typeId))) {
                    let filter = [...prevState.typesFilter];
                    let index = filter.indexOf(parseInt(typeId));
                    if (index > -1) {
                        filter.splice(index, 1);
                    }
                    return { typesFilter: filter };
                }
            }
        }, () => {
            this.filterReminders();
        });
    }

    filterReminders() {
        if(!this.state.reminders) {
            this.setState({ filteredReminders: this.state.reminders });
        }
        this.setState({
            filteredReminders: this.state.reminders.filter((reminder) => {
                if(!this.state.usersFilter.includes(reminder.assignedUser === null ? 0 : reminder.assignedUser.id)) {
                    return false;
                }
                return this.state.typesFilter.includes(parseInt(reminder.type.id));
            })
        });
    }

    dateChange(date) {
        const prefix = this.props.showAll ? "/reminders/all" : "/reminders";
        this.props.history.push(prefix + "/" + moment(date).format("YYYY-MM-DD"));
    }

    focusChange({ focused }) {
        this.setState({ datePickerFocussed: focused });
    }

    render() {
        return (
            <React.Fragment>
                <Helmet title={ this.props.showAll ? "Alle herinneringen" : "Herinneringen" }/>

                <ReminderEditModal
                    show={ this.state.showEditModal }
                    reminder={ this.state.editModalReminder }
                    handleClose={ () => this.setState({ showEditModal: false }) }
                    reminderUpdated={ this.reminderUpdated.bind(this) }
                />

                <Title
                    preTitle="Overzicht"
                    noBottom={ this.context.user.admin }
                    preChildren={
                        <div className="float-right mt-2">
                            <RemindersDatePicker
                                date={ this.state.date ? this.state.date : moment() }
                                focused={ this.state.datePickerFocussed }
                                onDateChange={ this.onDateChange }
                                onFocusChange={ this.onFocusChange }
                                calendarOpen={ this.state.calendarOpen }
                                setCalendarOpen={ (calendarOpen) => this.setState({ calendarOpen }) }
                            />
                        </div>
                    }
                >
                    Herinneringen
                    <small className="ml-2">
                        <TagPill>
                            { numberFormatter({ number: this.getAmountOfUnsolvedReminders() }) }
                        </TagPill>
                    </small>
                </Title>

                { this.context.user.admin && (
                    <TabBar>
                        <TabBarItem to="/reminders" activeUrl={ !this.props.showAll ? ["/reminders/:date", "/reminders"] : [] }>
                            Persoonlijk
                        </TabBarItem>
                        <TabBarItem to="/reminders/all" activeUrl={ this.props.showAll ? ["/reminders/all/:date", "/reminders/all"] : [] }>
                            Alle
                        </TabBarItem>
                    </TabBar>
                )}

                { this.state.error != null ? (
                    <Alert variant="danger">{ this.state.error }</Alert>
                ) : !this.state.filteredReminders ? (
                    <React.Fragment>
                        {[...Array(20)].map((value, index) => (
                            <div className="mb-3" key={ index }>
                                <Skeleton height={ 160 }/>
                            </div>
                        ))}
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        { this.props.showAll && this.state.users && (
                            <div className="card mb-3">
                                <div className="card-body">
                                    <div className="custom-control custom-checkbox user-picker-checkbox mr-4 mt-0" style={{ display: "inline-block" }}>
                                        <input type="checkbox" className="custom-control-input filterUser" id="checkbox0"
                                               checked={ this.state.usersFilter.includes(0) }
                                               onChange={ (event) => this.updateFilterUser(event, 0) }
                                        />
                                        <label className="custom-control-label" htmlFor="checkbox0">
                                            Iedereen
                                            <span className="badge badge-pill badge-secondary ml-2">
                                                { this.getAmountOfUnsolvedRemindersForUser(0) }
                                            </span>
                                        </label>
                                    </div>
                                    { this.state.users.map((user) => (
                                        <div key={user.id} className="custom-control custom-checkbox user-picker-checkbox mr-4 mt-0" style={{ display: "inline-block" }}>
                                            <input type="checkbox" className="custom-control-input" id={`checkbox-user${user.id}`}
                                                   checked={ this.state.usersFilter.includes(user.id) }
                                                   onChange={ (event) => this.updateFilterUser(event, user.id) }
                                            />
                                            <label className="custom-control-label" htmlFor={`checkbox-user${user.id}`}>
                                                { user.name }
                                                <span className="badge badge-pill badge-secondary ml-2">
                                                    { this.getAmountOfUnsolvedRemindersForUser(user.id) }
                                                </span>
                                            </label>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                        { this.state.types && (
                            <div className="card mb-3">
                                <div className="card-body">
                                    { this.state.types.map((type) => (
                                        <div key={type.id} className="custom-control custom-checkbox user-picker-checkbox mr-4 mt-0" style={{ display: "inline-block" }}>
                                            <input type="checkbox" className="custom-control-input" id={`checkbox-type${type.id}`}
                                                   checked={ this.state.typesFilter.includes(parseInt(type.id)) }
                                                   onChange={ (event) => this.updateFilterType(event, type.id) }
                                            />
                                            <label className="custom-control-label badge-group" htmlFor={`checkbox-type${type.id}`}>
                                                <TagPill color={ type.color }>
                                                    { type.name }
                                                </TagPill>
                                                <span className="badge badge-pill badge-secondary">
                                                    { this.getAmountOfUnsolvedRemindersWithType(type.id) }
                                                </span>
                                            </label>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                        { (this.getAmountOfUnsolvedReminders() === 0 || this.state.filteredReminders.length === 0) && (
                            <div className="text-center mb-4">
                                { (this.props.showAll || this.getAmountOfUnsolvedReminders() > 0) ? (
                                    <React.Fragment>
                                        <h1><i className="fas fa-alarm-clock"/></h1>
                                        <h3>Geen herinneringen</h3>
                                        <p>
                                            Er zijn geen herinneringen voor je huidige filters.
                                        </p>
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment>
                                        <h1><i className="fas fa-sparkles"/></h1>
                                        <h3>Geen herinneringen</h3>
                                        <p>
                                            Je bent helemaal bij met alle herinneringen.
                                            <br/>
                                            Nieuwe herinneringen kun je toevoegen bij een lead, installatie, storing of BTW Terugvraag.
                                        </p>
                                    </React.Fragment>
                                )}
                            </div>
                        )}
                        { this.state.filteredReminders.map(reminder => (
                            <ReminderCard
                                key={ reminder.id }
                                reminder={ reminder }
                                handleEditModalOpen={ this.openReminderEditModal.bind(this) }
                                reminderUpdated={ this.reminderUpdated.bind(this) }
                                showTitle
                                hideWhenSolved
                            />
                        ))}
                    </React.Fragment>
                ) }

            </React.Fragment>
        )
    }
}
RemindersPage.contextType = AuthenticatedUserContext;

export default withRouter(RemindersPage);
