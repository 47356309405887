import React, {
    useCallback,
    useMemo,
    useState
} from "react";
import {
    Link
} from "react-router-dom";
import {
    Button,
    Modal
} from "react-bootstrap";
import {
    useWindowSize
} from "@zandor300/backoffice-framework";
import {
    Annotation,
    FeatureVisibility
} from "@zandor300/react-apple-mapkitjs";

import AppleMapsMap from "../../../components/apple-maps/AppleMapsMap";
import ImageRemoveModal from "./ImageRemoveModal";

function allowedToRemoveImage(image) {
    if(!image) {
        return false;
    }
    switch(image.parentType) {
        case "lead":
        case "outage":
        case "installation":
        case "product":
        case "task":
            return true;
        default:
            return false;
    }
}

function canSetAsThumbnail(image) {
    if(!image) {
        return false;
    }
    return image.parentType === "product";
}

function OpenParentButton({ image }) {
    if(!image || !image.parent) {
        return null;
    }
    let url = null
    let text = null;
    switch(image.parentType) {
        case "lead":
            url = "/lead/" + image.parent.id;
            text = "Lead openen";
            break;
        case "leadComment":
            url = "/lead/" + image.parent.leadId;
            text = "Lead openen";
            break;
        case "outage":
            url = "/outage/" + image.parent.id;
            text = "Storing openen";
            break;
        case "outageComment":
            url = "/outage/" + image.parent.outageId;
            text = "Storing openen";
            break;
        case "installation":
            url = "/installation/" + image.parent.id;
            text = "Installatie openen";
            break;
        case "installationComment":
            url = "/installation/" + image.parent.installationId;
            text = "Installatie openen";
            break;
        case "product":
            url = "/products/" + image.parent.brand.id + "/product/" + image.parent.id;
            text = "Product openen";
            break;
        default:
            return null;
    }
    return (
        <Link to={ url } className="btn btn-primary ml-2">
            { text }
        </Link>
    )
}

function ImageModal({ image, show, handleClose, onImageRemoved, showOpenParentButton, currentThumbnail, setAsThumbnail }) {
    const [showImageRemoveModal, setShowImageRemoveModal] = useState(false);
    const [renderMap, setRenderMap] = useState(false);
    const [showMap, setShowMap] = useState(false);
    const size = useWindowSize();

    const onMouseClickBackground = useMemo(() => {
        return (event) => {
            if(event.target.nodeName !== "IMG") {
                handleClose();
            }
        };
    }, [handleClose]);

    const allowedToRemove = useMemo(() => {
        return onImageRemoved !== undefined && allowedToRemoveImage(image);
    }, [onImageRemoved, image]);
    const canSetImageAsThumbnail = useMemo(() => {
        if(!image) {
            return false;
        }
        if(currentThumbnail && image.id === currentThumbnail.id) {
            return false;
        }
        return canSetAsThumbnail(image);
    }, [currentThumbnail, image]);

    const onRemove = useMemo(() => {
        return () => {
            setShowImageRemoveModal(true);
        };
    }, []);
    const onSetAsThumbnail = useMemo(() => {
        return () => {
            if(!setAsThumbnail || !image) {
                return;
            }
            setAsThumbnail(image);
        }
    }, [setAsThumbnail, image]);
    const handleImageRemoveModalClose = useMemo(() => {
        return () => {
            setShowImageRemoveModal(false);
        };
    }, [])
    const onImageRemove = useMemo(() => {
        return () => {
            onImageRemoved();
            handleImageRemoveModalClose();
            handleClose();
        };
    }, [onImageRemoved, handleImageRemoveModalClose, handleClose]);

    const toggleMap = useCallback(() => {
        if(showMap) {
            setShowMap(false);
            setTimeout(() => {
                setRenderMap(false);
            }, 500);
        } else {
            setRenderMap(true);
            setTimeout(() => {
                setShowMap(true);
            }, 50);
        }
    }, [showMap]);

    const imageContainerRef = useCallback((node) => {
        if(!node) {
            return;
        }
        node.style.setProperty("--map-size", size.width * 0.45 + "px");
    }, [size]);

    return (
        <React.Fragment>
            <ImageRemoveModal
                show={ show && showImageRemoveModal }
                handleClose={ handleImageRemoveModalClose }
                onImageRemoved={ onImageRemove }
                image={ image }
            />

            <Modal size="image" show={ show } onHide={ handleClose }>
                <Modal.Body className="text-center">
                    <div className="image-modal-container" ref={ imageContainerRef }>
                        <div className="image-modal-image" onClick={ onMouseClickBackground }>
                            <Button className="modal-image-close" variant="link" size="lg" onClick={ handleClose }>
                                <i className="fas fa-times"/>
                            </Button>
                            { image && (
                                <img
                                    src={ image.url }
                                    alt="Selected"
                                    style={{
                                        maxWidth: "100%",
                                        maxHeight: size.height
                                    }}
                                />
                            )}
                        </div>
                        { renderMap && image?.latitude && image?.longitude && (
                            <div className={ "image-modal-map" + (showMap ? "" : " collapsed") }>
                                <AppleMapsMap
                                    latitude={ image.latitude }
                                    longitude={ image.longitude }
                                    zoom={ 15 }
                                    height={ size.height }
                                    showsMapTypeControl={ false }
                                    showsZoomControl={ false }
                                    showsCompass={ FeatureVisibility.Hidden }
                                    colorScheme="dark"
                                    className="test"
                                >
                                    <Annotation
                                        latitude={ image.latitude }
                                        longitude={ image.longitude }
                                        color="#FF5000"
                                        title="Foto locatie"
                                    />
                                </AppleMapsMap>
                            </div>
                        )}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="d-flex w-100">
                        <div className="flex-grow-1">
                            { allowedToRemove && (
                                <Button variant="danger" className="mr-2" onClick={ onRemove }>
                                    <i className="fas fa-trash-alt mr-2"/>
                                    Verwijderen
                                </Button>
                            )}
                            { canSetImageAsThumbnail && (
                                <Button variant="secondary" className="mr-2" onClick={ onSetAsThumbnail }>
                                    <i className="fas fa-thumbtack mr-2"/>
                                    Instellen als hoofdfoto
                                </Button>
                            )}
                            { image?.latitude && image?.longitude && (
                                <Button variant="secondary" className="mr-2" onClick={ toggleMap }>
                                    <i className="fas fa-map-marker-alt mr-2"/>
                                    { showMap ? (
                                        "Kaart verbergen"
                                    ) : (
                                        "Kaart weergeven"
                                    )}
                                </Button>
                            )}
                        </div>
                        <div>
                            <Button variant="secondary" onClick={ handleClose } className="ml-2">
                                Sluiten
                            </Button>
                            { image && (
                                <React.Fragment>
                                    <a href={ image?.url } className="btn btn-secondary ml-2" target="_blank" rel="noreferrer noopener">
                                        Origineel openen
                                        <i className="fas fa-external-link-square-alt ml-2"/>
                                    </a>
                                    { showOpenParentButton && (
                                        <OpenParentButton image={ image }/>
                                    )}
                                </React.Fragment>
                            )}
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    )
}

export default ImageModal;
