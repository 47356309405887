import React from "react";
import axios from "axios";
import {
    Alert,
    Button,
    Modal
} from "react-bootstrap";
import ImageUploadingComponent from "../UploadImageComponent";

import Loading from "../../Loading";

class UploadImageModal extends React.PureComponent {
    getDefaultState() {
        return {
            error: null,
            loading: false,
            images: []
        }
    }

    constructor(props) {
        super(props);
        this.state = this.getDefaultState();
        this.onShow = this.onShow.bind(this);
        this.imageUploaderRef = React.createRef();
        this.onImagesChange = this.imagesChange.bind(this);
        this.uploadImages = this.uploadImages.bind(this);
    }

    imagesChange(imageList, addUpdateIndex) {
        console.log(imageList, addUpdateIndex);
        this.setState({ images: imageList });
    }

    async uploadImages() {
        this.setState({ loading: true });
        const images = this.imageUploaderRef.current?.getImages() || [];

        if (images.length === 0) {
            this.setState({ loading: false });
            this.props.imageUploaded();
            this.props.handleClose();
            return;
        }
    
        for (const image of images) {
            if (!await this.uploadImage(image)) {
                this.setState({ 
                    loading: false, 
                    error: "Er is een fout opgetreden bij het uploaden van de foto's. Niet alle foto's zijn geupload." 
                });
                return;
            }
        }
        this.setState({ loading: false });
        this.props.imageUploaded();
        this.props.handleClose();
    }

    async uploadImage(image) {
        if (image.file === null) {
            return false;
        }

        const formData = new FormData();
        Object.entries(this.props.requestParameters).forEach(([key, value]) => {
            formData.append(key, value);
        });
        formData.append("file", image.file, image.file.name);

        const fileExtension = image.file.name.split('.').pop().toLowerCase();
        let apiEndpoint = "/uploadFile";
        if(fileExtension === "jpg" || fileExtension === "jpeg" || fileExtension === "png") {
            apiEndpoint = "/uploadImage";
        }

        const response = await axios.post(apiEndpoint, formData, {
            transformRequest: (data, headers) => {
                headers.setContentType(undefined);
                return data;
            }
        });

        return !(!response.data || !response.data.valid);
    }

    onShow() {
        this.setState(this.getDefaultState())
    }

    render() {
        return (
            <Modal show={this.props.show} onHide={this.props.handleClose} onShow={this.onShow} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Foto uploaden</Modal.Title>
                </Modal.Header>
                {this.state.loading ? (
                    <Modal.Body>
                        <Loading />
                    </Modal.Body>
                ) : (
                    <React.Fragment>
                        <Modal.Body>
                            {this.state.error && (
                                <Alert variant="danger">{this.state.error}</Alert>
                            )}
                            <ImageUploadingComponent
                                ref={this.imageUploaderRef}
                                allowImages = {this.props.allowImages}
                                allowFiles = {this.props.allowFiles}
                                imageUploaded = {this.props.imageUploaded}
                                handleClose = {this.props.handleClose}
                            />
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={this.props.handleClose}>
                                Annuleer
                            </Button>
                            <Button variant="primary" onClick={this.uploadImages}>
                                Uploaden
                            </Button>
                        </Modal.Footer>
                    </React.Fragment>
                )}
            </Modal>
        )
    }
}

export default UploadImageModal;
