import React, { useCallback, useState } from 'react';

import axios from 'axios';

import BaseTaskModal from './BaseTaskModal';

const AddTaskModal = ({ show, handleClose, installationId, leadId, outageId, vatRefundId, taskAdded }) => {
    const [error, setError] = useState(null);

    const handleTaskAdd = useCallback(async ({ typeId, assignedUserId, deadlineDate, description, requiredAmount}) => {
        try {
            const response = await axios.post("/addTask", {
                installationId,
                leadId,
                outageId,
                vatRefundId,
                type: typeId,
                assignedUserId: assignedUserId,
                description: description,
                deadlineDate: deadlineDate,
                requiredAmount: requiredAmount,
            });

            if (response.data.valid) {
                return response.data.task;
            } else {
                setError("Er ging iets fout. Probeer het later opnieuw.");
                return null;
            }
        } catch (requestError) {
            console.error(requestError);
            setError("Er ging iets fout. Probeer het later opnieuw.");
            return null;
        }
    }, [installationId, leadId, outageId, vatRefundId]);

    return (
        <BaseTaskModal
            show={ show }
            handleClose={ handleClose }
            modalTitle="Taak toevoegen"
            onSave={ handleTaskAdd }
            taskAdded={ taskAdded }
            error={error}
        />
    );
}

export default AddTaskModal;
