import React, {
    useContext
} from "react";
import IntranetSidebarContext from "../IntranetSidebarContext";
import numberFormatter from "../../../formatters/NumberFormatter";

function TasksCountBadgeContent() {
    const sidebarContext = useContext(IntranetSidebarContext);
    if(sidebarContext.tasksCount === null) {
        return (
            <i className="fas fa-spinner-third fa-spin"/>
        );
    }
    if(sidebarContext.tasksCount === undefined) {
        return "Error";
    }
    return numberFormatter({ number: sidebarContext.tasksCount });
}

function TasksCountBadge() {
    return (
        <div className="badge badge-light float-right my-auto" style={{ whiteSpace: "nowrap" }}>
            <TasksCountBadgeContent/>
        </div>
    )
}

export default React.memo(TasksCountBadge);
