import React from "react";
import axios from "axios";

import DetailTasks from "../../../components/detail/DetailTasks";

class InstallationDetailTasks extends DetailTasks {
    getTasks() {
        this.setState({ tasks: null, error: null });
        axios.post("/getInstallationTasks", { installationId: this.props.installationId })
            .then((response) => {
                if(response.data.valid) {
                    this.setState({ tasks: response.data.data });
                } else {
                    this.setState({ tasks: null, error: "Er was een fout bij het ophalen van de data. (" + response.data.error + ")" });
                }
            })
            .catch((error) => {
                console.error(error);
                this.setState({ tasks: null, error: "Er was een fout bij het ophalen van de data." });
            });
    }

    getEmptyStateDescription() {
        return (
            <p>Er zijn nog geen taken toegevoegd aan deze installatie.</p>
        )
    }
}

export default InstallationDetailTasks;
