import React, {
    useCallback,
    useEffect,
    useState
} from "react";
import axios from "axios";
import {
    Redirect,
    Route,
    Switch
} from "react-router-dom";
import {
    TabBar,
    TabBarItem,
    Title
} from "@zandor300/backoffice-framework";

import Helmet from "../../../components/Helmet";
import Page404 from "../../Page404";

import InstallationDetailOverview from "./InstallationDetailOverview";
import InstallationDetailReminders from "./InstallationDetailReminders";
import InstallationDetailPlanning from "./InstallationDetailPlanning";
import InstallationDetailProducts from "./InstallationDetailProducts";
import InstallationDetailOutages from "./InstallationDetailOutages";
import InstallationDetailVATRefunds from "./InstallationDetailVATRefunds";
import InstallationDetailImages from "./InstallationDetailImages";
import InstallationDetailEdit from "./InstallationDetailEdit";
import InstallationDetailTasks from "./InstallationDetailTasks";

function InstallationDetail(props) {
    const installationId = props.match.params.id;
    const urlPrefix = "/installation/" + installationId;

    const [installation, setInstallation] = useState(null);
    const [error, setError] = useState(null);
    const [errorCode, setErrorCode] = useState(null);

    const refreshInstallation = useCallback(() => {
        setInstallation(null);
        setError(null);
        setErrorCode(null);
        axios.post("/getInstallation", { id: installationId })
            .then((response) => {
                if(response.data.valid) {
                    setInstallation(response.data.data);
                } else {
                    setInstallation(null);
                    setError("Er was een fout bij het ophalen van de data. (" + response.data.error + ")");
                    setErrorCode(response.data.error);
                }
            })
            .catch((error) => {
                console.error(error);
                setInstallation(null);
                setError("Er was een fout bij het ophalen van de data.");
            });
    }, [installationId]);

    useEffect(() => {
        if(isNaN(parseInt(installationId))) {
            return;
        }
        refreshInstallation();
    }, [installationId, refreshInstallation]);

    const childProps = {
        installationId,
        installation,
        error,
        setInstallation,
        refreshInstallation
    };

    if(isNaN(parseInt(installationId)) || installationId < 1 || errorCode === "DOESNT_EXIST") {
        return <Page404/>
    }

    const name = installation ? installation.lead.name : null;

    return (
        <React.Fragment>
            <Helmet title={ "Installatie #" + installationId + (name ? ` - ${name}` : "") }/>

            <Title preTitle="Detail" noBottom>
                Installatie <small className="text-muted">#{installationId}</small>
            </Title>

            <TabBar>
                <TabBarItem to={ urlPrefix }>
                    Overzicht
                </TabBarItem>
                <TabBarItem to={ urlPrefix + "/tasks" }>
                    Taken
                </TabBarItem>
                <TabBarItem to={ urlPrefix + "/reminders" }>
                    Herinneringen
                </TabBarItem>
                <TabBarItem to={ urlPrefix + "/planning" }>
                    Planning
                </TabBarItem>
                <TabBarItem to={ urlPrefix + "/products" }>
                    Materiaal
                </TabBarItem>
                <TabBarItem to={ urlPrefix + "/outages" }>
                    Storingen
                </TabBarItem>
                <TabBarItem to={ urlPrefix + "/vat-refunds" }>
                    BTW Terugvraag
                </TabBarItem>
                <TabBarItem to={ urlPrefix + "/images" }>
                    Foto's
                </TabBarItem>
                <TabBarItem to={ urlPrefix + "/edit" } className="float-right">
                    Bewerken
                </TabBarItem>
            </TabBar>

            <Switch>
                <Route path={ urlPrefix } exact>
                    <InstallationDetailOverview { ...childProps }/>
                </Route>
                <Route path={ urlPrefix + "/tasks" }>
                    <InstallationDetailTasks { ...childProps }/>
                </Route>
                <Route path={ urlPrefix + "/reminders" }>
                    <InstallationDetailReminders { ...childProps }/>
                </Route>
                <Route path={ urlPrefix + "/planning" }>
                    <InstallationDetailPlanning { ...childProps }/>
                </Route>
                <Route path={ urlPrefix + "/products" }>
                    <InstallationDetailProducts { ...childProps }/>
                </Route>
                <Route path={ urlPrefix + "/outages" }>
                    <InstallationDetailOutages { ...childProps }/>
                </Route>
                <Route path={ urlPrefix + "/vat-refunds" }>
                    <InstallationDetailVATRefunds { ...childProps }/>
                </Route>
                <Route path={ urlPrefix + "/images" }>
                    <InstallationDetailImages { ...childProps }/>
                </Route>
                <Route path={ urlPrefix + "/edit" }>
                    <InstallationDetailEdit { ...childProps }/>
                </Route>

                <Route path="/">
                    <Redirect
                        to={{
                            pathname: urlPrefix,
                            state: {from: "/"}
                        }}
                    />
                </Route>
            </Switch>

        </React.Fragment>
    );
}

export default React.memo(InstallationDetail);
