import React, {
    useCallback,
    useContext, useEffect, useState
} from "react";
import moment from "moment";
import Calendar from "react-calendar";
import {
    SidebarContext,
    useWindowSize
} from "@zandor300/backoffice-framework";

function StatisticsDateRangePicker({
    startDate,
    endDate,
    onDatesChange,
    calendarOpen,
    setCalendarOpen
}) {
    const sidebarContext = useContext(SidebarContext);
    const windowSize = useWindowSize();

    const [numberOfMonths, setNumberOfMonths] = useState(2);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (calendarOpen && !event.target.closest(".calendar-container") && !event.target.closest(".form-control")) {
                setCalendarOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [calendarOpen, setCalendarOpen]);

    const handleCalendarClick = useCallback(() => {
        setCalendarOpen(!calendarOpen);
    }, [calendarOpen, setCalendarOpen]);

    useEffect(() => {
        let newNumberOfMonths = 2;
        if (sidebarContext.mobile) {
            newNumberOfMonths = 1;
        }
        if (windowSize.width < 1280) {
            newNumberOfMonths = 1;
        }
        setNumberOfMonths(newNumberOfMonths);
    }, [sidebarContext.mobile, windowSize.width]);

    return (
        <>
            <div className="">
                <button className="form-control" onClick={handleCalendarClick}>
                    {startDate ? moment(startDate).format("L") : ""} - {endDate ? moment(endDate).format("L") : ""}
                </button>
            </div>
            {calendarOpen && (
                <div className="calendar-container">
                    <Calendar
                        selectRange
                        showDoubleView={numberOfMonths >= 2}
                        goToRangeStartOnSelect={false}
                        showNeighboringMonth={false}
                        allowPartialRange
                        value={[startDate, endDate]}
                        onChange={onDatesChange}
                        prevLabel={<i className="fas fa-chevron-left fa-fw" />}
                        nextLabel={<i className="fas fa-chevron-right fa-fw" />}
                        prev2Label={<i className="fas fa-chevrons-left fa-fw" />}
                        next2Label={<i className="fas fa-chevrons-right fa-fw" />}
                        minDetail="month"
                    />
                </div>
            )}
        </>
    )
}

export default React.memo(StatisticsDateRangePicker);
