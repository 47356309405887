import React, {
    useMemo,
    useState,
    useRef,
    useCallback,
    useEffect
} from "react";
import moment from "moment";

import ImageModal from "./modal/ImageModal";
import ResizableImage from "../../components/ResizableImage";
import { useWindowSize } from "@zandor300/backoffice-framework";

function ImageGalleryCell({ image, onClick, isThumbnail, style }) {
    const onClickImage = useMemo(() => {
        return () => {
            onClick(image);
        }
    }, [onClick, image]);
    return (
        <div className="image-box" onClick={ onClickImage } style={ style }>
            <ResizableImage image={ image } alt={ "Image " + image.id } size="315x315"/>
            <div className="image-box-overlay d-flex flex-column">
                <div className="flex-grow-1">
                    <h4>{ image.author.name }</h4>
                    <p>{ moment(image.date).calendar() }</p>
                </div>
                <div className="d-flex justify-content-end">
                    { image.latitude && image.longitude && (
                        <i className="fas fa-map-marker-alt ml-2" style={{ fontSize: "1.5rem" }}/>
                    )}
                    { isThumbnail && (
                        <i className="fas fa-image-polaroid ml-2" style={{ fontSize: "1.5rem" }}/>
                    )}
                </div>
            </div>
        </div>
    );
}

function ImageGallery({ images, onImageRemoved, showOpenParentButton = false, currentThumbnail, setAsThumbnail, targetRowHeight = 200 }) {
    const [showImageModal, setShowImageModal] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [cellSize, setCellSize] = useState(null);
    const containerRef = useRef();
    const windowSize = useWindowSize();

    const updateCellSize = useCallback(() => {
        if(!containerRef.current) {
            return;
        }
        const imagesPerRow = Math.ceil(containerRef.current.offsetWidth / targetRowHeight);
        setCellSize(100 / imagesPerRow + "%");
    }, [targetRowHeight]);

    useEffect(() => {
        updateCellSize();
    }, [windowSize, updateCellSize]);

    const handleImageModalClose = useMemo(() => {
        return () => {
            setShowImageModal(false);
        }
    }, []);

    const onClickImage = useMemo(() => {
        return (image) => {
            setSelectedImage(image);
            setShowImageModal(true);
        }
    }, []);

    const onSetAsThumbnail = useMemo(() => {
        return (image) => {
            setAsThumbnail(image, handleImageModalClose);
        }
    }, [setAsThumbnail, handleImageModalClose]);

    return (
        <div ref={ containerRef }>
            <ImageModal
                image={ selectedImage }
                show={ showImageModal }
                onImageRemoved={ onImageRemoved }
                handleClose={ handleImageModalClose }
                showOpenParentButton={ showOpenParentButton }
                currentThumbnail={ currentThumbnail }
                setAsThumbnail={ onSetAsThumbnail }
            />

            <div className="row no-gutters">
                { images.map((image) => {
                    const isThumbnail = currentThumbnail && currentThumbnail.id === image.id;
                    return (
                        <ImageGalleryCell
                            image={ image }
                            onClick={ onClickImage }
                            key={ image.id }
                            isThumbnail={ isThumbnail }
                            style={{
                                width: cellSize || "20%"
                            }}
                        />
                    );
                })}
            </div>
        </div>
    )
}

export default React.memo(ImageGallery);
