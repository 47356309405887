import React, { useState, useCallback } from "react";
import axios from "axios";
import {
    Alert,
    Button,
    Modal,
    Spinner
} from "react-bootstrap";

import Loading from "../../Loading";

const DeleteFileModal = ({ show, handleClose, onFileRemoved, file }) => {
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    const handleFileDelete = useCallback(() => {
        setLoading(true);
        axios.post("/removeFile", {
            fileId: file.id
        })
            .then((response) => {
                if (response.data.valid) {
                    onFileRemoved();
                } else {
                    setError("Er ging iets fout. Probeer het later opnieuw.");
                }
            })
            .catch((requestError) => {
                console.error(requestError);
                setError("Er ging iets fout. Probeer het later opnieuw.");
            })
            .finally(() => {
                setLoading(false);
            });
    }, [file, onFileRemoved]);

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Bestand verwijderen</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {error !== null && (
                    <Alert variant="danger">{error}</Alert>
                )}
                {file !== null ? (
                    <React.Fragment>
                        <p className="text-danger">
                            Weet je zeker dat je dit bestand wilt verwijderen? Dit kan niet ongedaan worden
                            gemaakt.
                        </p>
                    </React.Fragment>
                ) : (
                    <Loading />
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose} disabled={loading}>
                    Annuleer
                </Button>
                <Button variant="danger" onClick={handleFileDelete} disabled={loading}>
                    {loading && (
                        <Spinner animation="border" variant="light" size="sm" className="mr-2" />
                    )}
                    Verwijderen
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default DeleteFileModal;
