import React, {
    useCallback,
    useContext,
    useEffect,
    useState
} from "react";
import axios from "axios";
import {
    Redirect,
    Route,
    Switch
} from "react-router-dom";
import {
    TabBar,
    TabBarItem,
    Title
} from "@zandor300/backoffice-framework";

import AuthenticatedUserContext from "../../../context/AuthenticatedUserContext";
import Helmet from "../../../components/Helmet";
import Page404 from "../../Page404";
import initialsFormatter from "../../../components/formatters/InitialsFormatter";

import VATRefundDetailOverview from "./VATRefundDetailOverview";
import VATRefundDetailReminders from "./VATRefundDetailReminders";
import VATRefundDetailImages from "./VATRefundDetailImages";
import VATRefundDetailEdit from "./VATRefundDetailEdit";
import VATRefundDetailTasks from "./VATRefundDetailTasks";

function VATRefundDetail(props) {
    const authenticatedUserContext = useContext(AuthenticatedUserContext);

    const vatRefundId = props.match.params.id;
    const urlPrefix = "/vat-refund/" + vatRefundId;

    const [vatRefund, setVATRefund] = useState(null);
    const [error, setError] = useState(null);
    const [errorCode, setErrorCode] = useState(null);

    const refreshVATRefund = useCallback(() => {
        setVATRefund(null);
        setError(null);
        setErrorCode(null);
        axios.post("/getVATRefund", { id: vatRefundId })
            .then((response) => {
                if(response.data.valid) {
                    setVATRefund(response.data.data);
                } else {
                    setVATRefund(null);
                    setError("Er was een fout bij het ophalen van de data. (" + response.data.error + ")");
                    setErrorCode(response.data.error);
                }
            })
            .catch((error) => {
                console.error(error);
                setVATRefund(null);
                setError("Er was een fout bij het ophalen van de data.");
            });
    }, [vatRefundId]);

    useEffect(() => {
        if(isNaN(parseInt(vatRefundId))) {
            return;
        }
        refreshVATRefund();
    }, [vatRefundId, refreshVATRefund]);

    const childProps = {
        vatRefundId,
        vatRefund,
        error,
        setVATRefund,
        refreshVATRefund
    };

    if(isNaN(parseInt(vatRefundId)) || vatRefundId < 1 || errorCode === "DOESNT_EXIST") {
        return <Page404/>
    }

    const name = vatRefund ? initialsFormatter({ initials: vatRefund.initials }) + " " + vatRefund.lastName : null;
    const vatRefundPermission = authenticatedUserContext.user.vatRefund;

    return (
        <React.Fragment>
            <Helmet title={ "BTW Terugvraag #" + vatRefundId + (name ? ` - ${name}` : "") }/>

            <Title preTitle="Detail" noBottom>
                BTW Terugvraag <small className="text-muted">#{vatRefundId}</small>
            </Title>

            <TabBar>
                <TabBarItem to={ urlPrefix }>
                    Overzicht
                </TabBarItem>
                <TabBarItem to={ urlPrefix + "/tasks" }>
                    Taken
                </TabBarItem>
                <TabBarItem to={ urlPrefix + "/reminders" }>
                    Herinneringen
                </TabBarItem>
                { vatRefundPermission && (
                    <React.Fragment>
                        <TabBarItem to={ urlPrefix + "/images" }>
                            Foto's
                        </TabBarItem>
                        <TabBarItem to={ urlPrefix + "/edit" } className="float-right">
                            Bewerken
                        </TabBarItem>
                    </React.Fragment>
                )}
            </TabBar>

            <Switch>
                <Route path={ urlPrefix } exact>
                    <VATRefundDetailOverview { ...childProps }/>
                </Route>
                <Route path={ urlPrefix + "/tasks" }>
                    <VATRefundDetailTasks { ...childProps }/>
                </Route>
                <Route path={ urlPrefix + "/reminders" }>
                    <VATRefundDetailReminders { ...childProps }/>
                </Route>
                { vatRefundPermission && (
                    <Route path={ urlPrefix + "/images" }>
                        <VATRefundDetailImages { ...childProps }/>
                    </Route>
                )}
                { vatRefundPermission && (
                    <Route path={ urlPrefix + "/edit" }>
                        <VATRefundDetailEdit { ...childProps }/>
                    </Route>
                )}

                <Route path="/">
                    <Redirect
                        to={{
                            pathname: urlPrefix,
                            state: {from: "/"}
                        }}
                    />
                </Route>
            </Switch>

        </React.Fragment>
    );
}

export default React.memo(VATRefundDetail);
